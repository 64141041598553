import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Matrix from "../components/matrix";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";
import EditLink from "../components/newComponents/editLink";
import SocialMediaButtons from "../components/newComponents/socialShare";

export const query = graphql`
  query ProjectsEntryQuery($slug: String!) {
    entry: craftProjectsProjectsEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      url
      uri
      summary
      displayTitle
      sectionHandle
      projectDate
      countries
      clientName
      relatedAreaOfExpertise {
        title
      }
      relatedFocusArea {
        title
      }
      featureImage {
        ...AssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }

    site: site {
      siteMetadata {
        url
      }
    }
  }
`;

const countries = {"coteDivoire": "Côte d'Ivoire", "eswatini" : "eSwatini"};

const NewsEntry = ({ data: { entry, site },pageContext }) => (
  <Layout pageContext={pageContext}>
    <Seo title={entry.title} image={entry.featureImage[0]?.url} />
    <div className="main">
      <section className="section-inside" style={{ padding: "0" }}>
        <div className="shell">
          <SectionHead heading={entry.title} image={entry.featureImage[0]}/>
          <div className="section__inner">
            <aside className="section__aside">
              <ul className="list-info">
                {entry.relatedFocusArea.length > 0 ?
                  <li>
                    <p className="list__title"><strong>Focus Area: </strong></p>
                    <p className="list__details">
                      {entry.relatedFocusArea.map((item) => (
                        <>
                          {item.title}
                          <br/>
                        </>
                      ))}
                    </p>
                  </li>
                  :
                  <></>
                }
                {entry.relatedAreaOfExpertise.length > 0 ?
                  <li>
                    <p className="list__title">
                      <strong>Area of Expertise: </strong>
                    </p>
                    <p className="list__details">
                      {entry.relatedAreaOfExpertise.map((item) => (
                        <>
                          {item.title}
                          <br/>
                        </>
                      ))}
                    </p>
                  </li>
                  :
                  <></>
                }
                {entry.clientName ? 
                  <li>
                    <p className="list__title"><strong>Client: </strong></p>
                    <p className="list__details">{entry.clientName}</p>
                  </li>
                  :
                  <></>
                }
                {entry.countries.length > 0 ?
                  <li>
                    <p className="list__title"><strong>Countries: </strong></p>
                    <p className="list__details">
                      {entry.countries.map((item, i) => {

                        const result = item.replace( /([A-Z])/g, " $1");
                        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                        if(countries[item]) finalResult = countries[item];
                        
                        return (
                          <>
                            {finalResult}
                            <br/>
                          </>
                        );
                      })}
                    </p>
                  </li>
                  :
                  <></>  
                }
                {entry.projectDate ? 
                  <li>
                    <p className="list__title"><strong>Date:</strong></p>
                    <p className="list__details">{entry.projectDate}</p>
                  </li>
                  :
                  <></>
                }
                {entry.displayTitle ? 
                  <li>
                    <p className="list__title"><strong>Project name: </strong></p>
                    <p className="list__details">{entry.displayTitle}</p>
                  </li>
                  :
                  <></>
                }
              </ul>
            </aside>
            <div className="section__content">
              <Matrix blocks={entry.bodyContent}/>
              <SocialMediaButtons url={`${site.siteMetadata.url}/${entry.uri}`} title={`Genesis Analytics Project: ${entry.title}`} summary={entry.summary} twitterVia="GenesisEconomic" linkedInSource="Genesis Analytics"/>
              <EditLink sectionHandle={entry.sectionHandle} remoteId={entry.remoteId}/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NewsEntry;